// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-free-review-tsx": () => import("./../src/pages/landing/free-review.tsx" /* webpackChunkName: "component---src-pages-landing-free-review-tsx" */),
  "component---src-pages-services-cloud-consultancy-tsx": () => import("./../src/pages/services/cloud-consultancy.tsx" /* webpackChunkName: "component---src-pages-services-cloud-consultancy-tsx" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-iot-consultancy-tsx": () => import("./../src/pages/services/iot-consultancy.tsx" /* webpackChunkName: "component---src-pages-services-iot-consultancy-tsx" */),
  "component---src-pages-services-rapid-prototyping-tsx": () => import("./../src/pages/services/rapid-prototyping.tsx" /* webpackChunkName: "component---src-pages-services-rapid-prototyping-tsx" */),
  "component---src-pages-services-serverless-tsx": () => import("./../src/pages/services/serverless.tsx" /* webpackChunkName: "component---src-pages-services-serverless-tsx" */)
}

